import React from "react"
import { graphql } from "gatsby"
import Layout from "~components/layouts/default"
import ContactUs from "~components/global/contact-us"
import SEO from "~components/common/seo"
import Container from "~components/common/container"
import Product from "~components/products/index-item"

const Page = ({ data }) => (
  <Layout>
    <SEO title="Werkbankplatten" description="Schwer entflammbar, besonders hygenisch, chemisch beständig oder einfach nur schlagfest. Unsere Arbeitsplatten sind allen Anforderungen gewachsen." />
    <Container>
      <div className="xl:w-2/3 xl:mx-auto">
        <div className="gutter my-screen-lg lg:w-3/4">
          <h1>Werkbankplatten für einfach jede Anforderung.</h1>
          <p>Schwer entflammbar, besonders hygenisch, chemisch beständig oder einfach nur schlagfest. Unsere Arbeitsplatten sind den unterschiedlichsten Anforderungen gewachsen.</p>
        </div>
      </div>
    </Container>
    <Container>
      <div className="my-screen-lg md:flex md:flex-wrap">
        {data.products.edges.map(({node}) => (
          <Product className="gutter my-md md:w-1/2 md:my-lg" key={node.slug} product={node} />
        ))}
      </div>
    </Container>
    <ContactUs slug="thomas-schenk" />
  </Layout>
)

export const query = graphql`
  query {
    products: allProductsJson(sort: {order: ASC, fields: sort}) {
      edges {
        node {
          id
          slug
          name
          index_features
          image
          description
        }
      }
    }
  }
`

export default Page
