import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Features from "~components/common/features"
import Feature from "~components/common/feature"
import AniLink from "~components/common/anilink"
import Image from "gatsby-image"

const IndexItem = ({ product, ...props }) => (
  <>
    <StaticQuery
      query={graphql`
        query {
          images: allFile(filter: {relativePath: {glob: "products/**/*.(png|jpg)"}}) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 360, maxHeight: 240, quality: 90, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
                relativePath
              }
            }
          }
        }
      `}
      render={data => (
        <section {...props}>
          <div className="flex items-center">
            <figure className="sm:-mt-sm w-1/3 pr-gutter self-start">
              {product.image &&
                <AniLink className="block" to={`/werkbankplatten/${product.slug}`}>
                  <Image fluid={data.images.edges.find(({node}) => node.relativePath === product.image).node.childImageSharp.fluid} alt={product.name} title={product.name} />
                </AniLink>
              }
            </figure>
            <div className="w-2/3 pl-gutter">
              <header>
                <h2 className="my-0">{product.name}</h2>
                <div className="text-brown-light">{product.description}</div>
              </header>
              <Features className="my-sm">
                {product.index_features && product.index_features.map((feature, index) => (
                  <Feature key={index}>{feature}</Feature>
                ))}
              </Features>
              <AniLink className="accessory" to={`/werkbankplatten/${product.slug}`}>
                Details
              </AniLink>
            </div>
          </div>
        </section>
      )}
    />
  </>
)

IndexItem.propTypes = {
  product: PropTypes.object
}
  
export default IndexItem
  